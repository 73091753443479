<template>
  <div class="my-auto max-w-6xl w-full mx-auto">
    <container>
      <transition
        appear
        appear-active-class="appear-from"
        appear-to-class="appear-to"
      >
        <div class=" mb-4 sm:mb-12 md:mb-16">
          <h1
            class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-headings text-center mb-0 sm:mb-2"
            v-text="$t('title.payment')"
          />
        </div>
      </transition>
      <div class="flex flex-col md:flex-row -mx-6">
        <div class="px-6 w-full md:w-1/2">
          <box>
            <div class="p-6 md:px-8 md:py-12">
              <payment-form
                :price-single="priceSingle"
                :price-pack="pricePack"
              />
              <p class="text-center mt-8">
                <img src="/img/cards.svg" width="320" class="mx-auto" alt="" />
              </p>
              <p
                class="text-center text-xs opacity-75 mt-4"
                v-text="$t('misc.secure_payment')"
              />
            </div>
          </box>
        </div>
        <div class="px-6 w-full md:w-1/2 mt-12 md:mt-0">
          <sidebar-summary isEditable isOnPaymentPage />
          <p
            class="text-sm mt-8"
            v-text="
              '*' +
                $t(`offers.${offerSelected.slug}.legalText`, {
                  price: priceSingle,
                })
            "
          />
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import PaymentForm from "../components/PaymentForm";
import SidebarSummary from "../components/ZodiacFormComponents/SidebarSummary";
import Container from "../components/Ui/Container";
import Box from "../components/Ui/Box";
export default {
  name: "Payment",
  components: { PaymentForm, SidebarSummary, Container, Box },

  computed: {
    offerSelected() {
      return this.$store.state.offerSelected;
    },
    priceSingle() {
      return this.offerSelected.price;
    },
    pricePack() {
      return this.$store.state.offers[1].price;
    },
  },
  metaInfo() {
    return { title: this.$t("title.payment") };
  },
};
</script>
