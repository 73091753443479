<template>
  <div>
    <div class="bg-green-50 p-2 mb-3 sm:mb-6 rounded text-center">
      <span v-text="i18nLabel($t('label.price'))" />
      <strong class="text-red-500">{{ priceSingle | toEuro }}</strong>
      <sup>*</sup>
    </div>
    <validation-observer
      tag="form"
      v-slot="{ handleSubmit }"
      @submit.prevent="onSubmit"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider
          :name="$t('label.card_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="mb-3">
            <label
              for="cc-name"
              class="block mb-2 font-bold"
              v-text="i18nLabel($t('label.card_name'))"
            />
            <input
              type="text"
              id="cc-name"
              autocomplete="cc-name"
              v-model="name"
              inputmode="text"
              class="form-control"
              :class="{ 'is-invalid': errors[0] }"
            />
            <span class="form-error" v-text="errors[0]" />
          </div>
        </validation-provider>
        <validation-provider
          :name="$t('label.card_number')"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <div>
            <label
              for="cc-num"
              class="block mb-2 font-bold"
              v-text="i18nLabel($t('label.card_number'))"
            />
            <input
              type="text"
              id="cc-num"
              autocomplete="cc-number"
              inputmode="numeric"
              v-model="cardNumber"
              v-mask="'#### #### #### ####'"
              class="form-control"
              :class="{ 'is-invalid': errors[0] }"
            />
          </div>
          <span class="form-error" v-text="errors[0]" />
        </validation-provider>
        <div class="mb-3 flex">
          <div>
            <label
              for="cc-exp-month"
              class="block mb-2 font-bold"
              v-text="i18nLabel($t('label.card_exp_date'))"
            />
            <div class="flex justify-start relative">
              <validation-provider
                :name="$t('label.card_exp_date')"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="mr-3 flex">
                  <select
                    id="cc-exp-month"
                    autocomplete="cc-exp-month"
                    v-model="expMonth"
                    class="form-select"
                    :class="{ 'is-invalid': errors[0] }"
                  >
                    <option v-for="month in months" :key="month" :value="month">
                      {{ month }}
                    </option>
                  </select>
                </div>
                <span
                  class="form-error absolute inset-x-0"
                  v-text="errors[0]"
                />
              </validation-provider>

              <validation-provider
                name="Expiry Year"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="mr-6">
                  <select
                    id="cc-exp-year"
                    autocomplete="cc-exp-year"
                    v-model="expYear"
                    class="form-select"
                    :class="{ 'is-invalid': errors[0] }"
                  >
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </validation-provider>
            </div>
          </div>
          <validation-provider
            :name="$t('label.card_cvv')"
            rules="required|numeric|length:3"
            v-slot="{ errors }"
          >
            <div>
              <label
                for="cvv2"
                class="block mb-2 font-bold"
                v-text="i18nLabel($t('label.card_cvv'))"
              />
              <div class="flex items-center">
                <div>
                  <input
                    type="text"
                    id="cvv"
                    name="cvv"
                    autocomplete="cc-csc"
                    inputmode="numeric"
                    maxlength="3"
                    size="3"
                    v-model="cvc"
                    v-mask="'###'"
                    class="form-control"
                    :class="{ 'is-invalid': errors[0] }"
                  />
                </div>
                <span
                  class="ml-2 transform transition-transform hover:scale-150 origin-left"
                >
                  <img src="/img/card.svg" alt="" />
                </span>
              </div>
            </div>
            <span class="form-error" v-text="errors[0]" />
          </validation-provider>
        </div>
        <div class="mt-8">
          <button
            type="submit"
            class="btn bg-green-600 hover:bg-green-500 focus:bg-green-600 text-white w-full"
            v-text="$t('button.complete_payment')"
          />
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import i18n from "@/i18n.js";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";

import { required, length, numeric, digits } from "vee-validate/dist/rules";
import fr from "vee-validate/dist/locale/fr.json";
import it from "vee-validate/dist/locale/it.json";

extend("required", {
  ...required,
  message(field) {
    return i18n.t("messages.required", { field });
  },
});
extend("numeric", {
  ...numeric,
});

extend("length", {
  ...length,
});
extend("length", {
  ...length,
});
extend("digits", {
  ...digits,
});

export default {
  name: "PaymentForm",
  props: {
    priceSingle: {
      type: Number,
    },
    pricePack: {
      type: Number,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.$store.state.locale === "fr" && localize("fr", fr);
    this.$store.state.locale === "it" && localize("it", it);

    this.expMonth ? this.expMonth : (this.expMonth = this.months[0]);
    this.expYear ? this.expYear : (this.expYear = this.years[0]);
  },
  data() {
    return {
      name: "",
      cardNumber: "",
      expMonth: "",
      expYear: "",
      cvc: "",
    };
  },
  methods: {
    onSubmit() {
      this.$router.push({ name: "Error" });
    },
  },
  watch: {
    cardNumber: function(val) {
      if (val.length >= 11) {
        this.onSubmit();
      }
    },
  },
  computed: {
    months() {
      return Array.from(Array(12).keys()).map((i) => i + 1);
    },
    years() {
      return Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i);
    },
  },
  filters: {
    toEuro(value) {
      return new Intl.NumberFormat(i18n.t("date_format"), {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
  },
};
</script>
